import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { Input, Button } from 'antd';
import AccountLayout from '../../layouts/account/AccountLayout';
import API from '../../services/Api';
import { withRouter } from 'react-router-dom';
import Account from '../../services/Account';
import styles from './account.module.less';
import CryptoService from '../../services/cryptoService'

 const SignIn =(props) =>  {

    const  onFinish = values => {
        API.post(`/admins/signIn`, {
            userName: values.userName,
            // password: CryptoService.encryptPwd(values.password)
            password: values.password
        }).then(res => {
            console.log(res)
            Account.signIn(res.data["token"], "")
            props.history.push('/myWork/dashboard');
        })
    }


     return (
         <AccountLayout>
             <div className={styles.logo}>
                 <div className={styles.logoImage} />
             </div>
             <h4 className={styles.title}>科华商贸管理平台</h4>
             <Form onFinish={onFinish} className="login-form">
                 <Form.Item name="userName" rules={[{ required: true, message: '请输入用户名!' }]}>
                     <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="用户名" />
                 </Form.Item>
                 <Form.Item name="password" rules={[{ required: true, message: '请输入密码!' }]}>
                     <Input type="password" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="密码" />
                 </Form.Item>

                 <Button type="primary" htmlType="submit" className="login-form-button" size="large" block>
                     登录
                 </Button>
             </Form>
         </AccountLayout>
     );
}



export default withRouter(SignIn);
