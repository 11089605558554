import React, { useState} from 'react'
import { Table, Row, Col, Divider, Input} from 'antd'
import { Link, useParams } from 'react-router-dom'
import BasicLayout, {
    ModuleContent,
    ModuleTool
} from '../../layouts/basic/BasicLayout'
import useTable from '../../hooks/useTable'
import style from "./fee.module.less";
import DictSelect from "../../components/DictSelect";
import DateRangeSelect from "../../components/DateRangeSelect";
import DataSelect from "../../components/DataSelect.js";
import Util from "../../services/Util";


const PayList = () => {
    const [queryWord, setQueryWord] = useState('')
    const [page, setPage] = useState(0);
    const [status, setStatus] = useState(null)
    const [merchantId, setMerchantId] = useState(null);
    const [dateRange, setDateRange] = useState(Util.dateRange(3 * 365, "days"))
    let num = useParams().orderNumber;

    const columns = [
        {
            title: '支付流水号',
            dataIndex: ['payNo']
        },
        {
            title: '订单号',
            dataIndex: ['orderNo']
        },
        {
            title: '商户',
            dataIndex: ['merchant','title']
        },
        {
            title: '状态',
            dataIndex: ['status','title']
        },
        {
            title: '付款人',
            dataIndex: ['user','userName']
        },
        {
            title: '提交时间',
            dataIndex: ['submitDate']
        },
        {
            title: '付款时间',
            dataIndex: ['payDate'],
            render: (text, record) => (
                <div>
                    {record.payDate ? record.payDate : "-"} 
                </div>
            )
        },
        {
            title: '金额',
            dataIndex: ['depositAmount'],
            render: (text, record) => (
                <div>
                    {(record.depositAmount / 100).toFixed(2)} 
                </div>
            )
        },
        {
            title: '操作',
            dataIndex: 'id',
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <div>
                    <Link to={'/order/orderList/' + record.orderNo}>订单</Link>
                </div>
            )
        }
    ]

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    }

    const onStatusChange = (status) =>{
        setStatus(status);
    }
    const onMerchantChange = (merchant) => {
        setMerchantId(merchant);
    };

    const { tableProps, reloadTable } = useTable({
        url: '/payLogs/query',
        pageSize: 10,
        queryParams: {
            page,
            queryWord: num ? num : queryWord,
            merchantId: merchantId ? merchantId.id : null,
            status: status ? status.id : "",
            startDate: dateRange[0],
            endDate: dateRange[1]
        },
        onPageChange : (page) =>{
            setPage(page);
        }
    })
    
    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <DataSelect
                            allowClear
                            onChange={onMerchantChange}
                            dataUrl="/merchants/listAll"
                            placeholder="商户"
                            className={style.dict}
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onStatusChange}
                            categoryKey="PAY_STATUS"
                            placeholder="状态"
                        />
                        <Input.Search
                            placeholder="订单编号"
                            onSearch={g => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} scroll={{ x: 1500, y: 300 }}/>
            </ModuleContent>
        </BasicLayout>
    )
}

export default PayList
