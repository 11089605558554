import React, { useState, useEffect } from "react";
import { Row, Col, Table, Space } from "antd";
import API from "../../services/Api";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import style from "./report.module.less";
import moment from "moment";
import Util from "../../services/Util";
import DayColumnChart from "./DayColumnChart";
import BlockTitle from "../../components/BlockTitle";
import DateRangeSelect from "../../components/DateRangeSelect";

const OrderMonthReport = () => {
    const [dateRange, setDateRange] = useState([
        moment().subtract(3, "month"),
        moment(new Date()),
    ]);

    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            title: "日期",
            dataIndex: "day",
            render: (text, record) => {
                return <div>{record.day ? record.day.slice(0, 7) : ""}</div>;
            },
        },
        {
            title: "订单金额",
            dataIndex: "amount",
            render: (text, record) => {
                return Util.currencyFen(text, 2);
            },
        },
        {
            title: "订单量",
            dataIndex: "num",
        },
    ];
    const fetch = () => {
        const values = {
            startDate: moment(dateRange[0]).format("YYYY-MM-DD 00:00:00"),
            endDate: moment(dateRange[1]).format("YYYY-MM-DD 23:59:59"),
        };

        API.post("/report/orderReports/queryByMonth", values).then((res) => {
            setReportData(res.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetch();
    }, []);

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
        fetch();
    };

    return (
        <>
            <ModuleTool>
                <Row>
                    <Col flex="1">
                        <span className={style.report_title}>订单月报表</span>
                    </Col>

                    <Col flex="600px" className={style.query_area}>
                        <Space>
                            <span>订单日期区间</span>
                            <DateRangeSelect
                                picker={"month"}
                                value={dateRange}
                                onChange={dateRangeChange}
                            />
                        </Space>
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <DayColumnChart
                    title="订单金额趋势图"
                    data={reportData}
                    yField="amount"
                    yAlias="销售额"
                    color="#03a9f4"
                    currency={0}
                    type="month"
                />
                <DayColumnChart
                    title="订单量趋势图"
                    data={reportData}
                    yField="num"
                    yAlias="订单量"
                    color="#2ecc71"
                    type="month"
                />

                <BlockTitle>订单报表</BlockTitle>
                <Table
                    dataSource={reportData}
                    size="default"
                    columns={columns}
                    rowKey="day"
                    pagination={false}
                    loading={loading}
                />
            </ModuleContent>
        </>
    );
};

export default OrderMonthReport;
