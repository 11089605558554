import React, { useState, useEffect } from "react";
import { Row, Col, Table, Space } from "antd";
import API from "../../services/Api";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import style from "./report.module.less";
import moment from "moment";
import Util from "../../services/Util";
import DayColumnChart from "./DayColumnChart";
import BlockTitle from "../../components/BlockTitle";
import DateRangeSelect from "../../components/DateRangeSelect";

const DeviceMonthReport = () => {
    const [dateRange, setDateRange] = useState([
        moment().subtract(3, "month"),
        moment(new Date()),
    ]);

    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            title: "日期",
            dataIndex: "day",
            render: (text, record) => {
                return <div>{record.day ? record.day.slice(0, 7) : ""}</div>;
            },
        },
        {
            title: "新设备数",
            dataIndex: "deviceNum",
        },
        {
            title: "总设备数",
            dataIndex: "deviceTotalNum",
        },
        {
            title: "新桩数",
            dataIndex: "stubNum",
        },
        {
            title: "总桩数",
            dataIndex: "stubTotalNum",
        },
    ];
    const fetch = () => {
        const values = {
            startDate: moment(dateRange[0]).format("YYYY-MM-DD 00:00:00"),
            endDate: moment(dateRange[1]).format("YYYY-MM-DD 23:59:59"),
        };

        API.post("/report/deviceReports/queryByMonth", values).then((res) => {
            setReportData(res.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetch();
    }, []);

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
        fetch();
    };

    return (
        <>
            <ModuleTool>
                <Row>
                    <Col flex="1">
                        <span className={style.report_title}>设备月报表</span>
                    </Col>

                    <Col flex="600px" className={style.query_area}>
                        <Space>
                            <span>设备日期区间</span>
                            <DateRangeSelect
                                picker={"month"}
                                value={dateRange}
                                onChange={dateRangeChange}
                            />
                        </Space>
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
            <DayColumnChart
                    title="新设备数趋势图"
                    data={reportData}
                    yField="deviceNum"
                    yAlias="新设备数"
                    color="#03a9f4"
                    type="day"
                />
                <DayColumnChart
                    title="总设备数趋势图"
                    data={reportData}
                    yField="deviceTotalNum"
                    yAlias="总设备数"
                    color="#2ecc71"
                    type="day"
                />
                <DayColumnChart
                    title="新桩数趋势图"
                    data={reportData}
                    yField="stubNum"
                    yAlias="新桩数"
                    color="#03a9f4"
                    type="day"
                />
                <DayColumnChart
                    title="总桩数趋势图"
                    data={reportData}
                    yField="stubTotalNum"
                    yAlias="总桩数"
                    color="#2ecc71"
                    type="day"
                />

                <BlockTitle>订单报表</BlockTitle>
                <Table
                    dataSource={reportData}
                    size="default"
                    columns={columns}
                    rowKey="day"
                    pagination={false}
                    loading={loading}
                />
            </ModuleContent>
        </>
    );
};

export default DeviceMonthReport;
