import React from "react";
import { NavLink, withRouter, Redirect } from "react-router-dom";
import { Menu, Button,  Dropdown, PageHeader, Space } from "antd";
import { Layout } from "antd";
import style from "./basic.module.less";
import Account from "../../services/Account";
import store from "store";
import menuData, { menuIcons } from "../../configs/menu";
import configs from "../../configs/config";

import HeaderAvatar from "./HeaderAvatar"
import HeaderNotice from "./HeaderNotice"
import HeaderTitle from "./HeaderTitle"

const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu;

const ONLY_SHOW_ONE_MENU = true;

class BasicLayout extends React.Component {
    state = {
        collapsed: false,
        modifyPasswordVisible: false,
        openKeys: store.get("openKeys") || ["0"],
        selectMenuKey: "dashboard",

        authoritys: Account.info("accountAuthoritys")
    };

    onCollapse = collapsed => {
        this.setState({ collapsed });
        if (collapsed) {
            this.setState({
                openKeys: []
            });
        }
    };





    onMenuClick = menu => {};

    onBreakpoint = broken => {
        this.setState({
            collapsed: broken
        });
        if (broken) {
            this.setState({
                openKeys: []
            });
        }
    };

    onOpenChange = openKeys => {
        if(ONLY_SHOW_ONE_MENU){
            const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
            const keys = latestOpenKey ? [latestOpenKey] : [];
            this.setState({
                openKeys: keys
            });
            store.set("openKeys", keys);
        }else{
            this.setState({
                openKeys
            });
            store.set("openKeys", openKeys);
        }

        
    };



    render() {
        const MenuIcon = ({ type }) => {
            const MenuIcon = menuIcons[type];
            return <MenuIcon />;
        };

        if (!Account.logined()) {
            return <Redirect to="/account/signIn" />;
        } else {
            
            const { location } = this.props;

            const authedMenus = menuData.filter(p =>
                p.subMenus.find(
                    subMenu =>
                        !subMenu.authKey ||
                        this.state.authoritys.indexOf(subMenu.authKey) !== -1
                )
            );

            return (
                <Layout style={{ minHeight: "100vh" }}>
                    <Sider
                        className={style.sider}
                        breakpoint="lg"
                        onBreakpoint={this.onBreakpoint}
                        collapsed={this.state.collapsed}
                        onCollapse={this.onCollapse}
                    >
                        <div className={style.logo} />
                        <Menu
                            defaultOpenKeys={["0"]}
                            openKeys={this.state.openKeys}
                            selectedKeys={[location.pathname]}
                            onClick={this.onMenuClick}
                            onOpenChange={this.onOpenChange}
                            mode="inline"
                            theme="dark"
                        >
                            {authedMenus
                                .filter(
                                    menu =>
                                        menu.subMenus &&
                                        menu.subMenus.length > 0
                                )
                                .map((menu, index) => (
                                    <SubMenu
                                        key={index}
                                        title={
                                            <span>
                                                <MenuIcon type={menu.icon} />
                                                <span>{menu.title}</span>
                                            </span>
                                        }
                                    >
                                        {menu.subMenus.map(
                                            (subMenu, subIndex) => (
                                                <Menu.Item key={subMenu.url}>
                                                    <NavLink
                                                        to={subMenu.url}
                                                        className={style.link}
                                                    >
                                                        {subMenu.title}
                                                    </NavLink>
                                                </Menu.Item>
                                            )
                                        )}
                                    </SubMenu>
                                ))}
                        </Menu>
                    </Sider>
                    <Layout>
                        <Header className={style.header}>
                            <HeaderTitle title={configs.appTitle}/>
                            <Space className={style.right}>
                                {/* <HeaderNotice /> */}
                                <HeaderAvatar />
                            </Space>
                            

                        </Header>
                        <Content
                            className={style.content}
                            style={{
                                marginLeft: this.state.collapsed ? 98 : 218
                            }}
                        >
                            {this.props.children}
                            <div className={style.copyright}>
                                {configs.appTitle} <br /> &copy;{" "}
                                {configs.copyright}
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            );
        }
    }
}

export default withRouter(BasicLayout);

export class ModuleContent extends React.Component {
    render() {
        return <div className={style.moduleContent}>{this.props.children}</div>;
    }
}

export class ModuleHeaderWraped extends React.Component {
    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        return (
            <PageHeader
                style={{
                    padding: "0px"
                }}
                onBack={this.goBack}
                title={this.props.title}
                subTitle={this.props.subTitle}
                extra={this.props.extra}
            />
        );
    }
}

export const ModuleHeader = withRouter(ModuleHeaderWraped);

export class ModuleTool extends React.Component {
    render() {
        return <div className={style.moduleTool}>{this.props.children}</div>;
    }
}
