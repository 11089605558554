import React, { useState, useEffect, useRef } from "react";
import style from "./report.module.less";
import Util from "../../services/Util";
import { Column } from "@ant-design/charts";

const DayColumnChart = ({
    title,
    color = "#03a9f4",
    data,
    type,
    yField,
    yAlias,
    currency,
}) => {
    const newData = data.map((item) => {
        return {
            day: item.day
                ? type === "month"
                    ? item.day.slice(0, 7)
                    : item.day.slice(5, 12)
                : "",
            value:
                item[yField] > 0
                    ? currency != null
                        ? item[yField] / 100
                        : item[yField]
                    : 0,
        };
    });

    const config = {
        forceFit: true,
        data: newData,
        xField: "day",
        yField: "value",
        meta: {
            day: {
                alias: "日期",
            },
            value: {
                alias: yAlias,
            },
        },
        color: color,
        label: {
            visible: true,
            style: {
                fill: "#0D0E68",
                fontWeight: 600,
                opacity: 0.6,
            },
        },
    };

    return (
        <>
            <h3 style={{ marginTop: 20 }}>{title}</h3>
            <Column {...config} style={{ height: 300, width: "100%" }} />
        </>
    );
};

export default DayColumnChart;
