import React, { useState } from "react";
import { Table, Row, Col, Input, Button, Divider, Popconfirm } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import { Link } from "react-router-dom";
import DictSelect from "../../components/DictSelect";
import DateRangeSelect from "../../components/DateRangeSelect";
import useTable from "../../hooks/useTable";
import style from "./user.module.less";
import Util from "../../services/Util";
import API from "../../services/Api";
import Main from "../share/Main";

const UserList = () => {
    const [queryWord, setQueryWord] = useState("");
    const [status, setStatus] = useState(null);
    const [page, setPage] = useState(0);
    // const [actionName, setActionName] = useState("");

    const [dateRange, setDateRange] = useState(Util.dateRange(3, "days"));
    const path = "/user/userDetail/";
    const columns = [
        {
            title: "手机",
            dataIndex: ["mobile"],
            render: (id, record) => (
                <div>
                    <span>{Main.isAdmin() ? record.mobile : (record.mobile.substr(0,3) + "****" + record.mobile.substr(7,4))}</span>
                </div>
            ),
        },
        {
            title: "注册时间",
            dataIndex: ["createTime"],
        },
        {
            title: "备注",
            dataIndex: ["memo"],
        },
        {
            title: "操作",
            dataIndex: ["id"],
            render: (text, record) => (
                <div>
                    <Link to={path + record.id}>编辑</Link>
                </div>
            ),
        },
    ];

    const { tableProps, reloadTable } = useTable({
        url: "users/query",
        queryParams: {
            page,
            queryWord: queryWord,
            status: status ? status.id : "",
            startDate: dateRange[0],
            endDate: dateRange[1],
        },
        onPageChange: (page) => {
            setPage(page);
        },
    });

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    };

    const onStatusChange = (status) => {
        setStatus(status);
    };

    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onStatusChange}
                            categoryKey="GENERAL_STATUS"
                            placeholder="状态"
                        />
                        <Input.Search
                            placeholder="搜索"
                            onSearch={(g) => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} />
            </ModuleContent>
        </BasicLayout>
    );
};

export default UserList;
