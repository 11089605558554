import React, { useState} from 'react'
import { Table, Row, Col, Input} from 'antd'
import BasicLayout, {
    ModuleContent,
    ModuleTool
} from '../../layouts/basic/BasicLayout'
import useTable from '../../hooks/useTable'
import style from "./fee.module.less";
import DictSelect from "../../components/DictSelect";
import DateRangeSelect from "../../components/DateRangeSelect";
import DataSelect from "../../components/DataSelect.js";
import Util from "../../services/Util";


const MoneyList = () => {
    const [queryWord, setQueryWord] = useState('')
    const [page, setPage] = useState(0);
    const [status, setStatus] = useState(null)
    const [merchantId, setMerchantId] = useState(null);
    const [dateRange, setDateRange] = useState(Util.dateRange(3 * 365, "days"))
    
    const columns = [
        {
            title: '流水号',
            dataIndex: ['relativeInvoice']
        },
        {
            title: '订单号',
            dataIndex: ['orderNumber']
        },
        {
            title: '商户',
            dataIndex: ['merchantTitle']
        },
        {
            title: '用户',
            dataIndex: ['userName']
        },
        
        {
            title: '支付渠道',
            dataIndex: ['logTypeTitle']
        },
        {
            title: '金额',
            dataIndex: ['amount'],
            render: (text, record) => (
                <div>
                    {(record.amount / 100).toFixed(2)} 
                </div>
            )
        },
        {
            title: '状态',
            dataIndex: ['statusTitle']
        },
        {
            title: '创建时间',
            dataIndex: ['createTime']
        }
    ]

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    }

    const onStatusChange = (status) =>{
        setStatus(status);
    }
    const onMerchantChange = (merchant) => {
        setMerchantId(merchant);
    };

    const { tableProps, reloadTable } = useTable({
        url: '/moneys/query',
        pageSize: 10,
        queryParams: {
            page,
            queryWord: queryWord,
            merchantId: merchantId ? merchantId.id : null,
            statusId: status ? status.id : "",
            startDate: dateRange[0],
            endDate: dateRange[1]
        },
        onPageChange : (page) =>{
            setPage(page);
        }
    })
    
    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <DataSelect
                            allowClear
                            onChange={onMerchantChange}
                            dataUrl="/merchants/listAll"
                            placeholder="商户"
                            className={style.dict}
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onStatusChange}
                            categoryKey="STATUS"
                            placeholder="状态"
                        />
                        <Input.Search
                            placeholder="订单编号"
                            onSearch={g => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} />
            </ModuleContent>
        </BasicLayout>
    )
}

export default MoneyList
