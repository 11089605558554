import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import SignIn from "../pages/account/SignIn";
import NotFound from "../pages/account/NotFound";
import NotAuthed from "../pages/account/NotAuthed";

import Dashboard from "../pages/myWork/Dashboard";
import DeviceList from "../pages/device/DeviceList";
import DeviceEdit from "../pages/device/DeviceEdit";
import DeviceUpload from "../pages/device/DeviceUpload";
import StubList from "../pages/device/StubList";
import StubEdit from "../pages/device/StubEdit";
import SlotList from "../pages/device/SlotList";
import SlotEdit from "../pages/device/SlotEdit";
import StubUpload from "../pages/device/StubUpload";

import MerchantList from "../pages/merchant/MerchantList";
import MerchantEdit from "../pages/merchant/MerchantEdit";

import PositionList from "../pages/position/PositionList";
import PositionEdit from "../pages/position/PositionEdit";
import RuleList from "../pages/position/RuleList";
import RuleEdit from "../pages/position/RuleEdit";

import OrderList from "../pages/order/OrderList";
import OrderDetail from "../pages/order/OrderDetail";

import PayList from "../pages/fee/PayList";
import MoneyList from "../pages/fee/MoneyList";
import WithdrawalList from "../pages/fee/WithdrawalList";
import WithdrawalDetail from "../pages/fee/WithdrawalDetail";
import RefundList from "../pages/fee/RefundList";
import RefundDetail from "../pages/fee/RefundDetail";
// import MoneyOrderDetail from "../pages/fee/MoneyOrderDetail";
// import WithdrawalOrderDetail from "../pages/fee/WithdrawalOrderDetail";

import SmsList from "../pages/sms/SmsList";

import BulletinList from "../pages/cms/BulletinList";
import BulletinEdit from "../pages/cms/BulletinEdit";
import QuestionList from "../pages/cms/QuestionList";
import QuestionEdit from "../pages/cms/QuestionEdit";

import AdminList from "../pages/manager/AdminList";
import AdminEdit from "../pages/manager/AdminEdit";

import RoleList from "../pages/manager/RoleList";
import RoleEdit from "../pages/manager/RoleEdit";

import UserList from "../pages/user/UserList";
import UserDetail from "../pages/user/UserDetail";

import ConfigList from "../pages/manager/ConfigList";
import ConfigEdit from "../pages/manager/ConfigEdit";

import GenerateReport from "../pages/report/GenerateReport";
import UserReport from "../pages/report/UserReport";
import OrderReport from "../pages/report/OrderReport";
import DeviceReport from "../pages/report/DeviceReport";
import MerchantReport from "../pages/report/MerchantReport";

import MaintainList from "../pages/maintain/MaintainList";
import MaintainDetail from "../pages/maintain/MaintainDetail";




class RouteConfig extends Component {
    render() {
        return (
            <Switch>
                <Route path="/myWork/dashboard" component={Dashboard} />

                <Route path="/device/deviceList/:status" component={DeviceList} />
                <Route path="/device/deviceList" component={DeviceList} />
                <Route path="/device/deviceCreact" component={DeviceEdit} />
                <Route path="/device/deviceEdit/:id" component={DeviceEdit} />
                <Route path="/device/deviceUpload" component={DeviceUpload} />
                <Route path="/device/stubList" component={StubList} />
                <Route path="/device/stubCreact" component={StubEdit} />
                <Route path="/device/stubEdit/:id" component={StubEdit} />
                <Route path="/device/stubUpload" component={StubUpload} />
                <Route path="/device/slotList/:status" component={SlotList} />
                <Route path="/device/slotList/:id" component={SlotList} />
                <Route path="/device/slotList" component={SlotList} />
                <Route path="/device/slotEdit/:id" component={SlotEdit} />
                

                <Route path="/merchant/merchantList" component={MerchantList} />
                <Route path="/merchant/merchantCreate" component={MerchantEdit} />
                <Route path="/merchant/merchantEdit/:id" component={MerchantEdit} />

                <Route path="/position/positionList" component={PositionList} />
                <Route path="/position/positionCreate" component={PositionEdit} />
                <Route path="/position/positionEdit/:id" component={PositionEdit} />

                <Route path="/position/ruleList" component={RuleList} />
                <Route path="/position/ruleCreate" component={RuleEdit} />
                <Route path="/position/ruleEdit/:id" component={RuleEdit} />

                
                <Route path="/order/orderList/:orderNumber" component={OrderList} />
                <Route path="/order/orderListByStatus/:status" component={OrderList} />
                <Route path="/order/orderListOverdue/:overdue" component={OrderList} />
                <Route path="/order/orderList" component={OrderList} />
                <Route path="/order/orderDetail/:id" component={OrderDetail} />
                
                <Route path="/fee/payList/:orderNumber" component={PayList} />
                <Route path="/fee/payList" component={PayList} />

                <Route path="/fee/moneyList" component={MoneyList} />
 
                <Route path="/fee/withdrawalList/:orderNumber" component={WithdrawalList} />
                <Route path="/fee/withdrawalList" component={WithdrawalList} />
                <Route path="/fee/withdrawalDetail/:id" component={WithdrawalDetail} />
                <Route path="/fee/refundList/:orderNumber" component={RefundList} />
                <Route path="/fee/refundList" component={RefundList} /> 
                <Route path="/fee/refundDetail/:id" component={RefundDetail} />

                <Route path="/sms/smsList" component={SmsList} />

                <Route path="/cms/bulletinList" component={BulletinList} />
                <Route path="/cms/bulletinCreate" component={BulletinEdit} />
                <Route path="/cms/bulletinEdit/:id" component={BulletinEdit} />

                <Route path="/cms/questionList" component={QuestionList} />
                <Route path="/cms/questionCreate" component={QuestionEdit} />
                <Route path="/cms/questionEdit/:id" component={QuestionEdit} />


                <Route path="/user/userList" component={UserList} />
                <Route path="/user/userDetail/:id" component={UserDetail} />

                <Route path="/manager/adminList" component={AdminList} />
                <Route path="/manager/adminCreate" component={AdminEdit} />
                <Route path="/manager/adminEdit/:id" component={AdminEdit} />

                <Route path="/manager/roleList" component={RoleList} />
                <Route path="/manager/roleCreate" component={RoleEdit} />
                <Route path="/manager/roleEdit/:id" component={RoleEdit} />

                <Route path="/manager/configList" component={ConfigList} />
                <Route path="/manager/configEdit/:id" component={ConfigEdit} />
                
                <Route path="/report/generateReport" component={GenerateReport} />
                <Route path="/report/userReport" component={UserReport} />
                <Route path="/report/orderReport" component={OrderReport} />
                <Route path="/report/deviceReport" component={DeviceReport} />
                <Route path="/report/merchantReport" component={MerchantReport} />

                <Route path="/maintain/maintainList" component={MaintainList}/>
                <Route path="/maintain/maintainDetail/:id" component={MaintainDetail}/>
                

                {/* <Route path="/cms/questionList" component={QuestionList} />
                <Route path="/cms/questionCreate" component={QuestionEdit} />
                <Route path="/cms/questionEdit/:id" component={QuestionEdit} />

                <Route path="/cms/spotLightList" component={SpotLightList} />
                <Route path="/cms/spotLightCreate" component={SpotLightEdit} />
                
                <Route
                    path="/product/categoryCreate/:parentId"
                    component={ProductCategoryEdit}
                />
               
                <Route
                    path="/manager/auditLogDetail/:id"
                    component={AuditLogDetail}
                /> */}
                

                <Route path="/account/signIn" component={SignIn} />
                <Route path="/error/404" component={NotFound} />
                <Route path="/error/403" component={NotAuthed} />
                <Route exact path="/" component={SignIn} />

                <Route path="*" component={NotFound} />
            </Switch>
        );
    }
}

export default RouteConfig;
