import React, { useState } from "react";
import { Popconfirm, Form, Input, Button, message, Modal } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader,
} from "../../layouts/basic/BasicLayout";
import { useParams, useHistory } from "react-router-dom";
import useForm from "../../hooks/useForm";
import style from "./maintain.module.less";
import SpinBox from "../../components/SpinBox";
import Main from "../share/Main";
import API from "../../services/Api";
import ImageWall from "../../components/ImageWall";

const MaintainDetail = () => {
    let history = useHistory();
    const [statusId, setStatusId] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [did, setdDid] = useState(0);

    const handle = () => {
        API.post(`/maintains/handleDevice`, { id: did }).then((res) => {
            history.goBack();
        });
    };
    
    const onBeforeSave = (form, values) => {
        values.id = did;
    };

    const handleSuccess = () => {
        setShowModal(true)
    };

    const onModalCancel = () => {
        setShowModal(false);
    }

    const onFormLoad = (form, data) => {
        var images = [
            data.image1,
            data.image2,
            data.image3,
            data.image4,
            data.image5,
        ];
        images = images.filter((item) => {
            return item != null;
        });

        const imagesData = images.map((imageResource) => ({
            uid: imageResource.id,
            url: imageResource.url,
        }));

        form.setFieldsValue({
            id: data.id,
            images: imagesData,
            deviceUniqueId: data.device.uniqueId,
            positionTitle: data.position.title,
            categoryTitle: data.category.title,
            statusTitle: data.status.title,
            mobile: data.user.mobile,
            createTime: data.createTime,
            userName: data.staff ? data.staff.userName : "",
            beginProcessTime: data.beginProcessTime,
            finishTime: data.finishTime,
            remark: data.remark,
            result: data.processRemark,
        });

        setStatusId(data.status.id);
        setdDid(data.id);
    };

    const { formProps, loading, submitBtLayout, editMode, id } = useForm({
        id: useParams().id,
        formLoadUrl: "/maintains/get",
        saveMessage: "保存成功!",
        onFormLoad: onFormLoad,
        saveUrl: "/maintains/handleSuccess",
        onBeforeSave: onBeforeSave,
        onSaveFinish: () => {
            onModalCancel();
        }
    });

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="故障详情" />

                <Form {...formProps} className={style.form_content}>
                    <SpinBox loading={loading}>
                        <Form.Item label="故障图" name="images">
                            <ImageWall disabled={true}/>
                        </Form.Item>
                        <Form.Item label="故障设备唯一编码" name="deviceUniqueId">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="场所" name="positionTitle">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="类型" name="categoryTitle">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="状态" name="statusTitle">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="上报人员" name="mobile">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="上报时间" name="createTime">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="处理人员" name="userName">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="处理时间" name="beginProcessTime">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="处理完成时间" name="finishTime">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="问题描述" name="remark">
                            <Input disabled={true}></Input>
                        </Form.Item>
                        <Form.Item label="处理结果" name="processRemark">
                            <Input disabled={true}></Input>
                        </Form.Item>

                        <Form.Item {...submitBtLayout}>
                            {Main.isAfterSale() &&
                            statusId === "MAINTAIN_STATUS_SUBMIT" ? (
                                <Popconfirm
                                    title="确认开始处理故障?"
                                    loading={loading}
                                    onConfirm={handle}
                                >
                                    <Button>开始处理</Button>
                                </Popconfirm>
                            ) : (
                                ""
                            )}

                            {Main.isAfterSale() &&
                            statusId === "MAINTAIN_STATUS_PROCESSING" ? (
                                <Popconfirm
                                    title="处理完成?"
                                    loading={loading}
                                    onConfirm={handleSuccess}
                                >
                                    <Button>处理完成</Button>
                                </Popconfirm>
                            ) : (
                                ""
                            )}
                        </Form.Item>
                    </SpinBox>
                </Form>
            </ModuleContent>
            <Modal
                title="故障信息"
                visible={showModal}
                footer={null}
                getContainer={false}
                onCancel={onModalCancel}
            >
                <Form
                    {...formProps}
                    wrapperCol={{ span: 14 }}
                    labelCol={{ span: 7 }}
                >
                    <Form.Item
                        label="处理结果"
                        name="result"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 14, offset: 7 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            保存
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </BasicLayout>
    );
};

export default MaintainDetail;
