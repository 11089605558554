import React, { useState } from "react";
import {
    Table,
    Row,
    Col,
    Input,
    Button,
    Divider,
    message,
    Popconfirm,
} from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import { Link } from "react-router-dom";
import DictSelect from "../../components/DictSelect";
import DateRangeSelect from "../../components/DateRangeSelect";
import useTable from "../../hooks/useTable";
import style from "./merchant.module.less";
import Util from "../../services/Util";
import Main from "../share/Main";
import moment from "moment";
import API from "../../services/Api";

const MerchantList = () => {
    const [queryWord, setQueryWord] = useState("");
    const [status, setStatus] = useState();
    const [page, setPage] = useState(0);

    const [dateRange, setDateRange] = useState(Util.dateRange(7 * 365, "days"));
    const path = "/merchant/merchantEdit/";

    const columns = [
        {
            title: "名称",
            dataIndex: ["title"],
        },
        {
            title: "管理员",
            dataIndex: ["admin"],
            render: (text, record) => (
                <div>{record.admin ? record.admin.userName : "--"}</div>
            ),
        },
        {
            title: "联系电话",
            dataIndex: ["contact"],
            render: (text, record) => (
                <div>{record.contact ? record.contact : "--"}</div>
            ),
        },
        {
            title: "可用",
            dataIndex: ["enabled"],
            render: (text, record) => <div>{record.enabled ? "是" : "否"}</div>,
        },
        {
            title: "自动提现",
            dataIndex: ["autoTransfer"],
            render: (text, record) => <div>{record.autoTransfer ? "是" : "否"}</div>,
        },
        {
            title: "合同起止时间",
            dataIndex: ["contractStartTime"],
            render: (text, record) => (
                <div>
                    {moment(record.contractStartTime).format("YYYY-MM-DD") +
                        "~" +
                        moment(record.contractEndTime).format("YYYY-MM-DD")}
                </div>
            ),
        },
        {
            title: "操作",
            dataIndex: "id",
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <div>
                    <Link to={path + record.id}>编辑</Link>
                    {Main.isAdmin() ? (
                        <>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定操作吗?"
                                onConfirm={() => changeEnabled(record.id)}
                            >
                                {record.enabled ? <a>停用</a> : <a>启用</a>}
                            </Popconfirm>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            ),
        },
    ];

    const { tableProps, reloadTable } = useTable({
        url: "merchants/query",
        queryParams: {
            page,
            queryWord: queryWord,
            status: status,
            startDate: dateRange[0],
            endDate: dateRange[1],
        },
        onPageChange: (page) => {
            setPage(page);
        },
    });

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    };

    const onStatusChange = (status) => {
        if (status) {
            setStatus(status.id === "GENERAL_STATUS_ENABLED" ? true : false);
        } else {
            setStatus();
        }
    };

    const changeEnabled = (id) => {
        API.post("/merchants/changeEnabled", { id: id }).then((res) => {
            message.success("操作成功");
            reloadTable();
        });
    };

    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="300px">
                        {Main.isAdmin() ? (
                            <Button type="primary" style={{ marginRight: 8 }}>
                                <Link to="/merchant/merchantCreate">
                                    新增商户
                                </Link>
                            </Button>
                        ) : (
                            ""
                        )}
                    </Col>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onStatusChange}
                            categoryKey="GENERAL_STATUS"
                            placeholder="状态"
                        />
                        <Input.Search
                            placeholder="搜索"
                            onSearch={(g) => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} scroll={{ x: 1500, y: 300 }}/>
            </ModuleContent>
        </BasicLayout>
    );
};

export default MerchantList;
