import React, { useState} from 'react'
import { Table, Row, Col, Divider, Input} from 'antd'
import { Link, useParams } from 'react-router-dom'
import BasicLayout, {
    ModuleContent,
    ModuleTool
} from '../../layouts/basic/BasicLayout'
import useTable from '../../hooks/useTable'
import style from "./maintain.module.less";
import DictSelect from "../../components/DictSelect";
import DateRangeSelect from "../../components/DateRangeSelect";
import Util from "../../services/Util";
import Main from "../share/Main";

const MaintainList = () => {
    const [queryWord, setQueryWord] = useState('')
    const [page, setPage] = useState(0);
    const [status, setStatus] = useState(null)
    const [type, setType] = useState(null)
    const [dateRange, setDateRange] = useState(Util.dateRange(7, "days"))

    const columns = [
        {
            title: '故障床编号',
            dataIndex: ['device','uniqueId']
        },
        {
            title: '场所',
            dataIndex: ['position','title']
        },
        {
            title: '类型',
            dataIndex: ['category','title']
        },
        {
            title: '状态',
            dataIndex: ['status','title']
        },
        {
            title: '上报人员',
            dataIndex: ['user','mobile'],
            render: (text, record) => (
                <div>
                    {Main.isAdmin() ? record.user.mobile : ""}
                </div>
            )
            
        },
        {
            title: '上报时间',
            dataIndex: ['createTime']
        },
        {
            title: '处理人员',
            dataIndex: ['staff'],
            render: (text, record) => (
                <div>
                    {record.staff ? record.staff.userName + "-"+ record.staff.realName : ""}
                </div>
            )
        },
        {
            title: '处理时间',
            dataIndex: ['beginProcessTime']
        },

        {
            title: '处理完成时间',
            dataIndex: ['finishTime']
        },
        {
            title: '操作',
            dataIndex: 'id',
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <div>
                    <Link to={'/maintain/maintainDetail/' + record.id}>详情</Link>
                </div>
            )
        }
    ]

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    }

    const onStatusChange = (status) =>{
        setStatus(status);
    }

    const onTypeChange = (type) =>{
        setType(type);
    }

    const { tableProps, reloadTable } = useTable({
        url: '/maintains/query',
        pageSize: 10,
        queryParams: {
            page,
            queryWord: queryWord,
            statusId: status ? status.id : "",
            categoryId: type ? type.id : "",
            startDate: dateRange[0],
            endDate: dateRange[1]
        },
        onPageChange : (page) =>{
            setPage(page);
        }
    })
    
    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onStatusChange}
                            categoryKey="MAINTAIN_STATUS"
                            placeholder="状态"
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onTypeChange}
                            categoryKey="MAINTAIN_CATEGORY"
                            placeholder="类型"
                        />
                        <Input.Search
                            placeholder="故障床编号"
                            onSearch={g => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} scroll={{ x: 1500, y: 300 }}/>
            </ModuleContent>
        </BasicLayout>
    )
}

export default MaintainList
