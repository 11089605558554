import React, { useState } from "react";
import {
    Steps,
    Upload,
    message,
    Button,
    Row,
    Col,
    Popconfirm,
    Table,
    Form,
} from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader,
} from "../../layouts/basic/BasicLayout";
import { UploadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import store from "store";
import style from "./device.module.less";
import useForm from "../../hooks/useForm";
import API from "../../services/Api";
import SpinBox from "../../components/SpinBox";
import DictSelect from "../../components/DictSelect";

const { Step } = Steps;
const DeviceUpload = () => {
    const [stepValue, setStepValue] = useState(0);
    const [fid, setFid] = useState(0);
    const [list, setList] = useState([]);
    const [timestamp, setTimestamp] = useState(new Date().getTime().toString());

    const token = store.get('ob-token')
    const props = {
        name: "file",
        action: API.defaults.baseURL + "/files/uploadFile",
        data: {
            ["ob-token"]: token,
            ['timestamp']: timestamp,
            ["ob-channel"]: "ADMIN_CHANNEL_WEB_MANAGER",
        },
        onChange(info) {
            if (info.file.status === "done") {
                message.success(`${info.file.name} 文件上传成功`);
                setFid(info.file.response.id);
            } else if (info.file.status === "error") {
                message.error(`${info.file.name} 文件上传失败.`);
                setStepValue(0);
            }
        },
        transformFile(file) {
            setTimestamp(new Date().getTime().toString())
            return file;
        }
    };

    const columns = [
        {
            title: "桩唯一编码",
            dataIndex: "uniqueId",
        },
        {
            title: "桩类型",
            dataIndex: "deviceCategoryId",
        },
        {
            title: "槽数量",
            dataIndex: "slotNum",
        },
        {
            title: "状态",
            dataIndex: "status",
            render: (text, record) => (
                <div>
                    <span>{record.status === "1" ? "可用" : "不可用"}</span>
                </div>
            ),
        },
        {
            title: "操作",
            dataIndex: "uniqueId",
            render: (text, record) => (
                <div>
                    <Popconfirm
                        title="确定要删除吗?"
                        onConfirm={() => deleteRow(record.uniqueId)}
                    >
                        <a>删除</a>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    const onStepsClick = () => {
        if (stepValue === 0) {
            if (fid === 0) {
                message.error("请先上传文件");
            }

            API.post(`/stubs/processFile`, { fid }).then((res) => {
                setList(res.data);
            });
            setStepValue(1);
        }

        if (stepValue === 1) {
            if (list.length < 1) {
                message.error("当前没有需要保存的数据");
            }
            setStepValue(2);
        }
    };

    const deleteRow = (uniqueId) => {
        var newList = list.filter(function (item) {
            return item.uniqueId != uniqueId;
        });
        setList(newList);
    };

    const onBeforeSave = (form, values) => {
        values.bleVersion = values.bleVersion.id;
        values.deviceType = values.deviceType.id;
        values.vendor = values.vendor.id;
        values.json = JSON.stringify(list);
    };

    const { formProps, submitBtLayout, id } = useForm({
        id: useParams().id,
        saveUrl: "/stubs/saveFileData",
        saveMessage: "保存成功!",
        onBeforeSave: onBeforeSave,
    });

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="批量导入桩" />

                <Steps current={stepValue} className={style.stepItem}>
                    <Step title="文件上传" />
                    <Step title="文件处理" />
                    <Step title="保存数据" />
                </Steps>

                {stepValue === 0 ? (
                    <div flex="1" className={style.uploadItem}>
                        <Upload {...props}>
                            <Button>
                                <UploadOutlined /> 点击上传
                            </Button>
                        </Upload>
                        <Row>
                            <Col flex="auto" className={style.query_area}>
                                <Button
                                    type="primary"
                                    ghost
                                    onClick={onStepsClick}
                                    disabled={fid === 0}
                                >
                                    下一步
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ) : stepValue === 1 ? (
                    <div>
                        <ModuleContent>
                            <Table
                                dataSource={list}
                                size="default"
                                columns={columns}
                                rowKey="uniqueId"
                                pagination={false}
                            />
                            <Row>
                                <Col flex="auto" className={style.query_area}>
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={onStepsClick}
                                        disabled={list.length < 1}
                                    >
                                        下一步
                                    </Button>
                                </Col>
                            </Row>
                        </ModuleContent>
                    </div>
                ) : (
                    <div>
                        <ModuleContent>
                            <Form {...formProps} className={style.form_content}>
                                <SpinBox loading={false}>
                                    <Form.Item
                                        label="厂商"
                                        name="vendor"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DictSelect categoryKey="DEVICE_VENDOR" />
                                    </Form.Item>

                                    <Form.Item
                                        label="蓝牙版本"
                                        name="bleVersion"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DictSelect categoryKey="DEVICE_BLE_VERSION" />
                                    </Form.Item>

                                    <Form.Item
                                        label="设备类型"
                                        name="deviceType"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <DictSelect categoryKey="DEVICE_TYPE" />
                                    </Form.Item>

                                    <Form.Item {...submitBtLayout}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{ marginRight: 8 }}
                                        >
                                            保存
                                        </Button>
                                    </Form.Item>
                                </SpinBox>
                            </Form>
                        </ModuleContent>
                    </div>
                )}
            </ModuleContent>
        </BasicLayout>
    );
};

export default DeviceUpload;
