import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "antd";
import API from "../../services/Api";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import BlockTitle from "../../components/BlockTitle";
import style from "./report.module.less";
import Util from "../../services/Util";
import moment from "moment";
import DayColumnChart from "./DayColumnChart";
import DateRangeSelect from "../../components/DateRangeSelect";

const UserDayReport = () => {
    const [dateRange, setDateRange] = useState(Util.dateRange(30, "days"));
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            title: "日期",
            dataIndex: "day",
        },
        {
            title: "新用户数",
            dataIndex: "newNum",
        },
        {
            title: "总用户数",
            dataIndex: "totalNum",
        },
    ];

    const fetch = () => {
        const values = {
            startDate: moment(dateRange[0]).format("YYYY-MM-DD 00:00:00"),
            endDate: moment(dateRange[1]).format("YYYY-MM-DD 23:59:59"),
        };

        API.post("/report/userReports/query", values).then((res) => {
            setReportData(res.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetch();
    }, [dateRange]);

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
        fetch();
    };

    return (
        <>
            <ModuleTool>
                <Row>
                    <Col flex="1">
                        <span className={style.report_title}>用户日报表</span>
                    </Col>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <DayColumnChart
                    title="新增用户趋势图"
                    data={reportData}
                    yField="newNum"
                    yAlias="新用户数"
                    color="#03a9f4"
                />
                <DayColumnChart
                    title="累计用户趋势图"
                    data={reportData}
                    yField="totalNum"
                    yAlias="累计用户"
                    color="#f97b63"
                />

                <BlockTitle>报表</BlockTitle>
                <Table
                    dataSource={reportData}
                    size="default"
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                    loading={loading}
                />
            </ModuleContent>
        </>
    );
};
export default UserDayReport;
