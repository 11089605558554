import React, { useState } from "react";
import {
    Table,
    Row,
    Col,
    Input,
    Button,
    Divider,
    message,
    Popconfirm,
} from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import { Link } from "react-router-dom";
import DictSelect from "../../components/DictSelect";
import DateRangeSelect from "../../components/DateRangeSelect";
import useTable from "../../hooks/useTable";
import style from "./position.module.less";
import Util from "../../services/Util";
import Main from "../share/Main";
import API from "../../services/Api";
import DataSelect from "../../components/DataSelect.js";

const PositionList = () => {
    const [queryWord, setQueryWord] = useState("");
    const [status, setStatus] = useState();
    const [merchantId, setMerchantId] = useState(null);
    const [page, setPage] = useState(0);
    const [category, setCategory] = useState("");
    const [dateRange, setDateRange] = useState(Util.dateRange(7 * 365, "days"));
    const path = "/position/positionEdit/";

    const onStatusChange = (status) => {
        if (status) {
            setStatus(status.id === "GENERAL_STATUS_ENABLED" ? true : false);
        } else {
            setStatus();
        }
    };

    const onCategoryChange = (c) => {
        setCategory(c);
    };

    const columns = [
        {
            title: "名称",
            dataIndex: ["title"],
        },
        {
            title: "商户",
            dataIndex: ["merchant", "title"],
        },
        {
            title: "设备型号",
            dataIndex: ["deviceCategory", "title"],
        },

        {
            title: "规则",
            dataIndex: ["rule", "title"],
        },
        {
            title: "可用",
            dataIndex: ["enabled"],
            render: (text, record) => <div>{record.enabled ? "是" : "否"}</div>,
        },
        {
            title: "禁用时间",
            dataIndex: "supportDisabledPeriod",
            render: (text, record) => (
                <div>{record.supportDisabledPeriod ? "是" : "否"}</div>
            ),
        },
        {
            title: "操作",
            dataIndex: "id",
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <div>
                    <Link to={path + record.id}>编辑</Link>
                    {Main.isMerchant() ? (
                        <>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定操作吗?"
                                onConfirm={() => changeEnabled(record.id)}
                            >
                                {record.enabled ? <a>停用</a> : <a>启用</a>}
                            </Popconfirm>
                        </>
                    ) : (
                        ""
                    )}
                    {record.rule ? (
                        <>
                            <Divider type="vertical" />
                            <Link to={"/position/ruleEdit/" + record.rule.id}>
                                查看规则
                            </Link>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            ),
        },
    ];

    const { tableProps, reloadTable } = useTable({
        url: "positions/query",
        queryParams: {
            page,
            queryWord: queryWord,
            merchantId: merchantId ? merchantId.id : null,
            status: status,
            deviceCategoryId: category ? category.id : "",
            startDate: dateRange[0],
            endDate: dateRange[1],
        },
        onPageChange: (page) => {
            setPage(page);
        },
    });

    const onMerchantChange = (merchant) => {
        setMerchantId(merchant);
    };

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    };

    const changeEnabled = (id) => {
        API.post("/positions/changeEnabled", { id: id }).then((res) => {
            message.success("操作成功");
            reloadTable();
        });
    };

    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="300px">
                        {Main.isMerchant() ? (
                            <Button type="primary" style={{ marginRight: 8 }}>
                                <Link to="/position/positionCreate">
                                    新增场所
                                </Link>
                            </Button>
                        ) : (
                            ""
                        )}
                    </Col>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <DataSelect
                            allowClear
                            onChange={onMerchantChange}
                            dataUrl="/merchants/listAll"
                            placeholder="商户"
                            className={style.dict}
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onCategoryChange}
                            categoryKey="DEVICE_CATEGORY"
                            placeholder="型号"
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onStatusChange}
                            categoryKey="GENERAL_STATUS"
                            placeholder="状态"
                        />
                        <Input.Search
                            placeholder="搜索"
                            onSearch={(g) => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} scroll={{ x: 1500, y: 300 }}/>
            </ModuleContent>
        </BasicLayout>
    );
};

export default PositionList;
