import React, { useState, useEffect } from "react";
import { Select } from "antd";
import API from "../services/Api";

const { Option } = Select;
/* 数据集格式
[{
    id : 1, 
    title : "foo"
},{
    id : 2, 
    title : "bar"
}]
*/
const DataSelect = ({
    value,
    onChange,
    dataUrl,
    ...restProps
}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        setLoading(true);
        API.post(dataUrl, {
        })
            .then(res => {
                setLoading(false);
                setData(res.data);
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const handleChange = value => {
        if (onChange) {
            onChange(toMyComponentValue(value));
        }
    };

    const toAntComponentValue = v => {
        return v ? {
            key: v.id,
            label: v.title
        } : null;
    };

    const toMyComponentValue = v => {
        return v ? {
            id: v.key,
            title: v.label
        } : null;
    };



    const valueProp = value ? { value: toAntComponentValue(value) } : "";

    return (
        <Select
            placeholder="请选择"
            {...restProps}
            loading={loading}
            onChange={handleChange}
            labelInValue
            {...valueProp}
        >

            {data.map(item => (
                <Option value={item.id} key={item.id}>
                    {item.title ? item.title : item.name ? item.name : item.userName}
                </Option>
            ))}
        </Select>
    );
};

export default DataSelect;
