import React, { Component } from 'react'
import { EyeOutlined, LikeOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Input, List, Row, Col, message, Modal } from 'antd'
import BasicLayout, {
    ModuleContent,
    ModuleTool, ModuleHeader
} from '../../layouts/basic/BasicLayout'
import API from '../../services/Api'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import Util from '../../services/Util'
import DictPublish from './DictPublish'
import style from './cms.module.less'

const { Paragraph } = Typography
const { Search } = Input

class BulletinList extends Component {
    state = {
        list: [],
        modalText: '',
        pagination: {},
        loading: true,
        query: {
            queryWord: '',
            publish: '',
            page: 0,
            size: 5
        }
    }

    componentDidMount() {
        this.fetch()
    }

    pageOnChange = page => {
        this.state.query.page = page - 1
        this.fetch()
    }

    onSearch = value => {
        this.state.query.page = 0
        this.state.query.queryWord = value
        this.fetch()
    }

    fetch() {
        this.setState({ loading: true })
        API.post(`/bulletin/query`, this.state.query).then(res => {
            const list = res.data.results

            this.setState({
                list: list,
                loading: false,
                pagination: {
                    onChange: this.pageOnChange,
                    current: this.state.query.page + 1,
                    pageSize: this.state.query.size,
                    total: res.data.total,
                    showSizeChanger: false
                }
            })
        })
    }

    imageStyle = url => {
        return Util.imageStyle(url, 'r_21')
    }

    showModal = (status, e) => {
        const id = e.target.id
        this.setState({
            visible: true,
            id,
            status,
            modalText: status ? '确认取消发布吗？' : '确认发布吗？'
        })
    }

    handleOk = e => {
        this.setState({
            visible: false
        })
        const { id, status } = this.state
        if (status) {
            API.post('/contents/unPublish', { id }).then(res => {
                message.success('操作成功！')
                this.fetch()
            })
        } else {
            API.post('/contents/publish', { id }).then(res => {
                message.success('发布成功！')
                this.fetch()
            })
        }
    }

    handleCancel = e => {
        this.setState({
            visible: false
        })
    }

    changeStatus = value => {
        this.state.query.publish = value
        this.fetch()
    }

    render() {
        const { list, pagination, loading, visible, modalText } = this.state

        return (
            <BasicLayout>
                <ModuleTool>
                    <Row>
                        <Col flex="100px">
                            <Button type="primary">
                                <Link to="/cms/bulletinCreate">新增公告</Link>
                            </Button>
                        </Col>
                        <Col flex="auto" className={style.query_area}>
                            <Search
                                placeholder="标题查询"
                                onSearch={this.onSearch}
                                className={style.query}
                            />
                        </Col>
                    </Row>
                </ModuleTool>
                <ModuleContent>
                    <List
                        itemLayout="vertical"
                        size="large"
                        pagination={pagination}
                        dataSource={list}
                        loading={loading}
                        renderItem={item => (
                            <List.Item
                                key={item.id}
                                actions={[
                                    <span>
                                        <EyeOutlined
                                            style={{ marginRight: 8 }}
                                        />
                                        {item.viewCount}
                                    </span>,
                                    <span>
                                        <LikeOutlined
                                            style={{ marginRight: 8 }}
                                        />
                                        {item.likeCount}
                                    </span>,
                                    <Link to={'/cms/bulletinEdit/' + item.id}>
                                        编辑
                                    </Link>,
                                    <a
                                        id={item.id}
                                        onClick={e =>
                                            this.showModal(item.publish, e)
                                        }
                                        className={item.publish ? 'desc' : ''}
                                    >
                                        {item.publish ? '取消发布' : '发布'}
                                    </a>
                                ]}
                                extra={
                                    <img
                                        width={180}
                                        alt="logo"
                                        src={this.imageStyle(item.image.url)}
                                    />
                                }
                            >
                                <List.Item.Meta
                                    title={item.title}
                                    description={
                                        <Paragraph>{item.shortDesc}</Paragraph>
                                    }
                                />
                                {item.content}
                            </List.Item>
                        )}
                    />
                    <Modal
                        title="提示"
                        visible={visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                        <p>{modalText}</p>
                    </Modal>
                </ModuleContent>
            </BasicLayout>
        )
    }
}

export default BulletinList
